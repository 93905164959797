<template>
    <iframe src="https://www.youtube.com/embed/_AjZ14XUQEQ" allowfullscreen></iframe>
</template>

<script>
    export default {
        name: 'Video',
    }
</script>

<style scoped lang="scss">

    iframe {
        border: 0;
        width: 640px;
        height: 320px;
    }

    @media only screen and (max-width: 600px) {
        iframe {
            width: 400px;
        }
    }

    @media only screen and (max-width: 400px) {
        iframe {
            width: 200px;
        }
    }

</style>
