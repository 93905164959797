<template>
    <div id="app">
        <YtVideo/>
        <LinkFooter/>
    </div>
</template>

<script>

    import YtVideo from './components/YtVideo.vue';
    import LinkFooter from './components/LinkFooter.vue';

    export default {
        name: 'app',
        components: {
            YtVideo,
            LinkFooter,
        }
    }
</script>

<style lang="scss">

    body {
        height: 100%;
        color: #ffffff;
        background-color: #000000;
    }

    a, a:link, a:visited, a:hover, a:active {
        outline-style: none;
        text-decoration: none;
    }

    #app {
        text-align: center;
        margin: 60px 0;
    }
</style>
