<template>
    <div>
        <a target="_blank" class="mdc-icon-button" href="https://www.facebook.com/battalion.official">
            <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a target="_blank" class="mdc-icon-button" href="mailto:contact@battalion.ch">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'LinkFooter',
    }
</script>

<style scoped lang="scss">

    @import "~@material/icon-button/mdc-icon-button";

</style>
